export const colorSets = [
    {
      day: {
        backgroundColor1: 0x80b0a3,
        backgroundColor2: 0xceeae3,
        courtColor: 0x5d9a63,
        groundColor: 0xb17d60,
        sunColor: 0xffd75f
      },
      night: {
        backgroundColor1: 0x49637d,
        backgroundColor2: 0x75808d, 
        courtColor: 0x4f7653,
        groundColor: 0x936951,
        moonColor: 0xd3e8ff
      },
    },
    {
      day: {
        backgroundColor1: 0xdb947f,
        backgroundColor2: 0xabcfc7,
        courtColor: 0xc1583b,
        groundColor: 0xaa7260,
        sunColor: 0xffff9c
      },
      night: {
        backgroundColor1: 0x49637d,
        backgroundColor2: 0x75808d, 
        courtColor: 0x8d5141,
        groundColor: 0xaa7260,
        moonColor: 0xffffff
      },
    },
    {
      day: {
        backgroundColor1: 0xb68acb, //efc5bf
        backgroundColor2: 0xefc5bf, //b68acb 
        courtColor: 0x72a785,
        groundColor: 0x8a7da6,
        sunColor: 0xf9dcff

      },
      night: {
        backgroundColor1: 0x4049a3,
        backgroundColor2: 0x936da3, 
        courtColor: 0x53804e,
        groundColor: 0x665981,
        moonColor: 0xbaa0dc
      },
    },

    {
      day: {
        backgroundColor1: 0xbfdab7, 
        backgroundColor2: 0x96cab3, 
        courtColor: 0xff7369,
        groundColor: 0xe9a985,
        sunColor: 0xffc4a1
      },
      night: {
        backgroundColor1: 0x1b3828,
        backgroundColor2: 0x3d4e43, 
        courtColor: 0xb7635d,
        groundColor: 0xa0745a,
        moonColor: 0xffffff
      },
    },
    {
      day: {
        backgroundColor1: 0xc7f6ff, 
        backgroundColor2: 0x9cb7ce, 
        courtColor: 0xde8a8b,
        groundColor: 0xffb58d,
        sunColor: 0xffe684
      },
      night: {
        backgroundColor1: 0x70889a,
        backgroundColor2: 0x718596, 
        courtColor: 0x985d5e,
        groundColor: 0x97765d,
        moonColor: 0xffffff
      },
    },
    {
      day: {
        backgroundColor1: 0x82d5b9, 
        backgroundColor2: 0xb6d8d0, 
        courtColor: 0x3ea5c7,
        groundColor: 0xff977c,
        sunColor: 0xffbf86
      },
      night: {
        backgroundColor1: 0x44625d,
        backgroundColor2: 0x698983, 
        courtColor: 0x267d99,
        groundColor: 0xb27566,
        moonColor: 0xbfffd6
      },
      
    },
    {
      day: {
        backgroundColor1: 0x8c8c8c, 
        backgroundColor2: 0x8c8c8c, 
        courtColor: 0x2c2c2c,
        groundColor: 0x474747,
        sunColor: 0xffffff
      },
      night: {
        backgroundColor1: 0x6e6e6e,
        backgroundColor2: 0x6e6e6e, 
        courtColor: 0x171717,
        groundColor: 0x3f3f3f,
        moonColor: 0xdfdfdf
      },
      
    },
    // add more sets
  ];