import { useState, useEffect, useRef } from "react";
import { extend, useFrame, useLoader, useThree } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier";
import { Plane, Sparkles, useScroll, shaderMaterial, Circle, Html } from "@react-three/drei";
import * as THREE from 'three'
import Chair from "./Chair";
import Bucket from "./Bucket";
import Cloud from "./Cloud";
import { colorSets } from "./States/colorsets";
import Postprocessing from './Postprocessing';

export default function Environment(props)
{
    const [colors, setColors] = useState();
    const [darkMode, setDarkMode] = useState(false);
    const [lerpValue, setLerpValue] = useState(0);
    const [colorBtn, setColorBtn] = useState('black');
    const linesTexture = useLoader(THREE.TextureLoader, './Textures/CourtLines.png');
    const glowTexture = useLoader(THREE.TextureLoader, './Textures/GlowTex.png');
    const netTexture = useLoader(THREE.TextureLoader, './Textures/TennisNet.png');
    const sunRayTexture = useLoader(THREE.TextureLoader, './Textures/SunRayTexture.png');

    let lastTime = 0;
    let lowPerformanceCount = 0;

    const background = useRef();
    const materialRef = useRef()
    const court = useRef();
    const ground = useRef();
    const net = useRef();
    const sun = useRef()
    const moon = useRef()
    const sunRays = useRef();
    const moonRays = useRef();
    const stars = useRef()
    const stars1 = useRef()
    const stars2 = useRef()
    const stars3 = useRef()
    const sunGlow = useRef();
    const moonGlow = useRef();
    const sunRay1 = useRef();
    const sunRay2 = useRef();
   
   const scrollData = useScroll()
    const lightDay = useRef()
    const lightNight = useRef()

    const {camera} = useThree();
    const initialRotationX = -0.45; // Replace with your initial rotation.x
    const targetRotationX = 0;

    const GradientShaderMaterial = shaderMaterial(
        {
          topColorDay:      new THREE.Color(0x80b0a3),
          bottomColorDay:   new THREE.Color(0xceeae3),
          topColorNight:    new THREE.Color(0x202a35),
          bottomColorNight: new THREE.Color(0x936951),
          lerpValue: 0.0,
        },
        // Vertex Shader
        `
        varying vec2 vUv; 
        void main() {
          vUv = uv; 
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,
        // Fragment Shader
        `
        uniform vec3 topColorDay;
        uniform vec3 bottomColorDay;
        uniform vec3 topColorNight;
        uniform vec3 bottomColorNight;
        uniform float lerpValue;
        
        varying vec2 vUv;
      
        void main() {
          vec3 dayColor = mix(bottomColorDay, topColorDay, vUv.y);
          vec3 nightColor = mix(bottomColorNight, topColorNight, vUv.y);
          gl_FragColor = vec4(mix(dayColor, nightColor, lerpValue), 1.0);
        }`
      )
      
      extend({ GradientShaderMaterial })
      
      const changeColor =() =>{
        setDarkMode(!darkMode);
      };




      function animate() {
        const now = Date.now();
        const fps = 1000 / (now - lastTime);
        lastTime = now;
      
        if (fps < 30) {
          lowPerformanceCount++;
          if (lowPerformanceCount > 5) { // if low fps persists
            lightNight.current.visible = false; // disable the second light
            lightDay.current.castShadow = false; // disable shadow casting for the first light
          }
        } else {
          lowPerformanceCount = 0; // reset the counter if performance improves
        }

      }
      
      requestAnimationFrame(animate);

      const getDarkMode = ()=> {
        return darkMode;
      }

      useEffect(() => {
        if (darkMode)
        {
          setColors(colorSets[6])
          setColorBtn('black')

        }
        else
        {
          setColorBtn('lightblue')
          const randomIndex = Math.floor(Math.random() * 6);    
          setColors(colorSets[randomIndex]);
        }
      }, [darkMode]);


      useEffect(() => {
        setDarkMode(false)
        // Initial random color setting
        const randomIndex = Math.floor(Math.random() * (colorSets.length-1));    
        setColors(colorSets[randomIndex]);
          
        // Function to change color based on keypress
        const handleKeyDown = (e) => {
          if (e.keyCode >= 49 && e.keyCode <= 57) {  // ASCII for numbers 1-9
            const index = e.keyCode - 49;  // Convert ASCII to index (0-8)
            if (index < colorSets.length-1) {
              setColors(colorSets[index]);
            }
          }
        };
    
        // Attach the event listener
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup: remove event listener
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

      useEffect(() => {
        if (colors)
        {
            materialRef.current.uniforms.topColorDay.value.set(colors.day.backgroundColor1);
            materialRef.current.uniforms.topColorNight.value.set(colors.night.backgroundColor1);
            materialRef.current.uniforms.bottomColorDay.value.set(colors.day.backgroundColor2);
            materialRef.current.uniforms.bottomColorNight.value.set(colors.night.backgroundColor2);
        }
      }, [colors]);

      useEffect(()=>{
        props.setDM(darkMode);
      }, [darkMode])

      useEffect(() => {
        let myDiv = document.getElementById('container');

        if (scrollData.range(0,1)>0.965)
        {
          myDiv.style.display = "block";
        }
        else
        {
          myDiv.style.display = "none";
        }
      }, [scrollData.range(0,1)])

      useFrame(({ clock, delta }) => 
      {   
        let speed = 0.03
        if (sun.current && moon.current) {
          sun.current.position.x = -18 * Math.cos(-clock.elapsedTime * speed);
          sun.current.position.y = -18 * Math.sin(-clock.elapsedTime * speed);
    
          moon.current.position.x = 18 * Math.cos(-clock.elapsedTime * speed);
          moon.current.position.y = 18 * Math.sin(-clock.elapsedTime * speed);
        }     
        
        const minY = -18; const maxY = 18;
        const normalizedY = (sun.current.position.y - minY) / (maxY - minY);
        setLerpValue(1-normalizedY)
        materialRef.current.uniforms.lerpValue.value = 1 - normalizedY;      
        setLerpValue(1-normalizedY);

        if (colors)
        {
            ground.current.material.color.lerpColors(
                new THREE.Color(colors.day.groundColor),
                new THREE.Color(colors.night.groundColor),
                1 - normalizedY
            );
            
            court.current.material.color.lerpColors(
            new THREE.Color(colors.day.courtColor),
            new THREE.Color(colors.night.courtColor),
            1 - normalizedY
            );
            sun.current.material.emissive = new THREE.Color(colors.day.sunColor);
            sun.current.material.color = new THREE.Color(colors.day.sunColor);
            sunGlow.current.material.color = new THREE.Color(colors.day.sunColor);
            sunGlow.current.material.color = new THREE.Color(colors.day.sunColor)
            sunRay1.current.material.color = new THREE.Color(colors.day.sunColor)
            sunRay2.current.material.color = new THREE.Color(colors.day.sunColor)
            moon.current.material.emissive = new THREE.Color(colors.night.moonColor);
            moonGlow.current.material.color = new THREE.Color(colors.night.moonColor);
            
        }

        lightDay.current.intensity =  (normalizedY) * 0.1;
        lightNight.current.intensity = (1-normalizedY) * 0.05;
        lightDay.current.position.x = sun.current.position.x/4
        lightNight.current.position.x = moon.current.position.x/4

        //MOVE CAMERA ON SCROLL!
        const scrollValue = scrollData.range(0, 1);
        let newRotationX = initialRotationX;    
        if (scrollValue > 0.03) {
          // Calculate how much to adjust rotation.x based on the portion of the scroll range
          const proportion = (scrollValue - 0.03) / (0.15 - 0.03);
          newRotationX = initialRotationX + proportion * (targetRotationX - initialRotationX);
        }
    
        // Smoothly transition to the new rotation.x
        camera.rotation.x += (newRotationX - camera.rotation.x) * 0.2;
        if (camera.rotation.x>0)
        {
          camera.rotation.x = 0;
        }
    });

    return <>
    
      <Cloud z={-20}/>
      <Cloud z={-20.5}/>
      <Cloud z={-21}/>
      <Cloud z={-21.5}/>
      <Cloud z={-22}/>
      <Cloud z={-22.5}/>
    
        
        <directionalLight ref={lightDay}
            //color={day ? 'white' : 'darkblue'}
            castShadow
            intensity={0.5}
            rotation={[0, 1, 0]}
            position={[-5, 5, -3]}
            shadow-mapSize-width={512}
            shadow-mapSize-height={512}
            shadow-camera-near={0.1}
            shadow-camera-far={50}
            shadow-camera-left={-20}
            shadow-camera-right={20}
            shadow-camera-top={20}
            shadow-camera-bottom={-20}
            shadow-bias={0.01} 
            shadow-radius={0}
        />
      
        <directionalLight ref={lightNight}
            castShadow
            intensity={0.2}
            rotation={[0, 1, 0]}
            position={[-5, 5, -3]}
            shadow-mapSize-width={512}
            shadow-mapSize-height={512}
            shadow-camera-near={0.1}
            shadow-camera-far={50}
            shadow-camera-left={-20}
            shadow-camera-right={20}
            shadow-camera-top={20}
            shadow-camera-bottom={-20}
            shadow-bias={0.01} 
            shadow-radius={0}
        />
        <ambientLight intensity={0.7} color='white'/>

        <Plane ref = {background} args = {[400,60]} position={[0,22,-35]} rotation-x={-0.2}>
            <gradientShaderMaterial ref={materialRef} attach="material" />
        </Plane>
        
        <group ref={stars} visible rotation-x = {0}>
          <Sparkles ref={stars1} count={50} scale={[200,50,0]} size={50} opacity= {Math.max(0,lerpValue - 0.7)} speed={0.8} position={[0,10,-30]} matrixWorldNeedsUpdate={true}/>
          <Sparkles ref={stars2} count={25} scale={[200,50,0]} size={50} opacity= {Math.max(0,lerpValue -0.4)} speed={0.8} position={[0,10,-30]} matrixWorldNeedsUpdate={true}/>
          <Sparkles ref={stars3} count={25} scale={[200,50,0]} size={100} opacity= {Math.max(0,lerpValue -0.6)} speed={0.4} position={[0,10,-30]} matrixWorldNeedsUpdate={true}/>
        </group>

        <RigidBody  type='fixed' restitution={0.5}> 
            <mesh ref={ground} receiveShadow position-y={-0.6 } position-z={3.5} >
                <boxGeometry args={ [ 400, 0.5, 67.5 ] } />
                <meshToonMaterial color={"white"} />
            </mesh>
        </RigidBody> 

        <RigidBody  type='fixed' restitution={0.8}>    
            <mesh ref = {court} receiveShadow={true} position-y={-0.3} rotation-x = {-Math.PI/2}>
                <planeGeometry args={ [30, 40] } />
                <meshToonMaterial side={THREE.DoubleSide}/>
            </mesh>
        </RigidBody>  

        <mesh receiveShadow position-y={-0.25} rotation-x = {-Math.PI/2}>
            <planeGeometry args={ [30, 40] } />
            <meshToonMaterial color={ "white"} map={linesTexture} transparent/>
        </mesh>
          
  
        <mesh ref = {net} receiveShadow position-y={0.5}>
            <boxGeometry args={ [ 30, 4, 0.1 ]} />
            <meshToonMaterial color={"white"} transparent map={netTexture}/>
        </mesh>
               
        {/* Sun */}
        <Circle ref = {sun} args = {[2,20]} position={[20,0, -30]} scale={0.8}>
              <meshPhysicalMaterial 
              //toneMapped={false} emissiveIntensity={0.5} 
              />
              <group ref={sunRays}>        
                <Plane ref= {sunRay1} args = {[3, 15]} position={[0,0,-0.01]} rotation-z={Math.PI/2}> 
                    <meshPhysicalMaterial transparent opacity= {0.3} map={sunRayTexture} emissiveIntensity={0} >
                    </meshPhysicalMaterial>     
                </Plane>

                <Plane ref = {sunRay2} args = {[3, 15]} position={[0,0,-0.02]} > 
                    <meshPhysicalMaterial transparent opacity= {0.3} map={sunRayTexture} emissiveIntensity={0}>
                    </meshPhysicalMaterial>     
                </Plane>
                {/* Sun Glow */}
                <Circle ref={sunGlow} args = {[15, 30]} position={[0,0,-0.05]} scale = {1.8}> 
                    <meshStandardMaterial transparent opacity= {0.6} map={glowTexture} emissiveIntensity={10}>
                    </meshStandardMaterial>     
                </Circle>               
            </group>
        </Circle>    

        {/* Moon */}
        <Circle ref= {moon} args = {[2,8]} position={[-20,0, -30]} scale={0.8}>
            <meshPhysicalMaterial 
            //toneMapped={false} emissiveIntensity={0.2}
            /> 
            {/* Moon Glow */}
            <group ref= {moonRays}>
              <Circle ref={moonGlow} args = {[15, 30]} position={[0,0,-0.02]} scale = {1.3}> 
                  <meshStandardMaterial transparent color={'white'} opacity= {0.5} map={glowTexture} blending={THREE.AdditiveBlending}>
                  </meshStandardMaterial>     
              </Circle>     
            </group>
        </Circle>            
  

        <Plane args = {[200,21.6]} position={[0,-10.5, 38]}>
            <meshToonMaterial color={darkMode? 0x797979:0xfff7a3} />
        </Plane>    

        <Plane args = {[200,25]} position={[0,-30, 35]} >
            <meshToonMaterial color={darkMode? 0xffffff:0xde9dc2} />
        </Plane>   

        <Plane args = {[200,35]} position={[0,-59.5, 35]}>
            <meshToonMaterial color={0x404041} />
        </Plane>  

        <Plane args = {[200,35]} position={[0,-94.5, 35]} >
            <meshToonMaterial color={darkMode? 0xb7b7b7: 0xff8a61} />
        </Plane>  

        <Plane args = {[200,35]} position={[0,-129.5, 35]} >
            <meshToonMaterial color={darkMode? 0x575757: 0x96b1d4} />
        </Plane>  

        <Plane args = {[200,40]} position={[0,-167, 35]}>
            <meshToonMaterial color={darkMode? 0xc4c4c4: 0xeaded5} />
        </Plane>  

        <Plane args = {[200,40]} position={[0,-190.5, 35]}>
            <meshToonMaterial color={darkMode? 0x00000: 0xeeccd3} />
        </Plane>  

        <Chair/>

        <Bucket/>     

        <Postprocessing dark={darkMode} scrollData={scrollData}/>
     
        <Html>
        <label className="switch-container"           
            style={{
                top: visualViewport.height * -0.45, 
                right: -visualViewport.width * 0.48, 
                border: '2px',
               
              }}>
          <input type="checkbox"/>
          <span className="slider" 
              style={{
                background: colorBtn,
                border: '0.25px solid  white'
              }}       
          onClick={changeColor}>
            
          </span>
        </label>
              
    </Html>    
   
    </>


}
