import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Plane } from "@react-three/drei";
import * as THREE from 'three'

export default function Cloud(props) 
{
    const cloudRef = useRef();
    const [speed, setSpeed] = useState(0);
    const [randomPos, setRandomPos] = useState([0,0,0])
    const [opacity, setOpacity] = useState(0)
    const [scale, setScale] = useState(1)
    var cloudTexture = useLoader(THREE.TextureLoader, './Textures/cloud.png');


    const resetCloud = () =>
    {
        setSpeed(Math.random() * 2 + 0.5);
        setRandomPos([Math.random() * 20 - 80, Math.random() * 10 + 10, props.z])
        setOpacity(Math.max(0.3, Math.random() * 0.9))
        setScale(Math.max(0.6, Math.random()* 1));
    }

    useEffect(() => {
        resetCloud();
    }, []);   

    useFrame((state, delta) => {

        if (cloudRef.current) {
            cloudRef.current.position.x += delta * speed;

            // Reset position if the cloud moved out of visible area
            if (cloudRef.current.position.x > 60) 
            {
                resetCloud();
            }
        }
    });

  return (
        <Plane args={[10,5]} ref={cloudRef} scale = {scale} position = {randomPos}>
            <meshPhysicalMaterial color='white' side={THREE.DoubleSide} blending={THREE.NormalBlending} 
            alphaMap={cloudTexture} 
            map={cloudTexture} 
            transparent={true} opacity = {opacity}/>
        </Plane>
  );
};

