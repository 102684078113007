
import React, { useRef } from "react";
import { useGLTF, Sphere } from "@react-three/drei";

export default function Bucket()
{
  const { nodes, materials } = useGLTF("/Models/Bucket/Bucket.glb");
  return (
    <group dispose={null}>
      <mesh
        castShadow
        geometry={nodes.Tennis_Ball_Bucket.geometry}
        scale={0.03}
        position = {[-18,-0.2,5]}
        rotation = {[0,1.1,0]}
      >
      
      <meshPhysicalMaterial color= 'pink' />
      </mesh>
      <Sphere args={[0.3,10]} position = {[-17.7,1.5, 4.9]}><meshToonMaterial color={"#ccff00"} /></Sphere>
      <Sphere args={[0.3,10]} position = {[-18.3,1.6, 5.1]}><meshToonMaterial color={"#ccff00"} /></Sphere>
      <Sphere args={[0.3,10]} position = {[-17.8,2.1, 5.1]}><meshToonMaterial color={"#ccff00"} /></Sphere>
      <Sphere castShadow args={[0.3,10]} position = {[-16.5, 0, 5.1]}>
        <meshToonMaterial color={"#ccff00"} 
        />
      </Sphere>
    </group>

  );
}

useGLTF.preload("/Models/Bucket/Bucket.glb");