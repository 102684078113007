import { EffectComposer, Bloom , Pixelation, HueSaturation, BrightnessContrast} from "@react-three/postprocessing";
import { BlendFunction } from 'postprocessing'

import { useEffect, useState } from "react";
import { useFrame } from '@react-three/fiber';


export default function Postprocessing(props)
{
    // const m1 = document.getElementById("r1")
    // const [initiate,setInitiate] = useState(true)
    // const [g,setG] = useState(10);
    
    // useEffect(() => {
    //     setInitiate(false)
    // },[])

    useEffect(() => {
        if (props.dark)
        {
            if ( document.getElementById("serveButton"))
                document.getElementById("serveButton").style.backgroundColor = "#ffffff"
            if (document.getElementById("leftButton"))
                document.getElementById("leftButton").style.backgroundColor = "#ffffff"
            if (document.getElementById("rightButton"))
                document.getElementById("rightButton").style.backgroundColor = "#ffffff"
        }
        else
        {
            if ( document.getElementById("serveButton"))
                document.getElementById("serveButton").style.backgroundColor = "#f2ef96"
            if (document.getElementById("leftButton"))
                document.getElementById("leftButton").style.backgroundColor = "#f2ef96"
            if (document.getElementById("rightButton"))
                document.getElementById("rightButton").style.backgroundColor = "#f2ef96"
        }
    },[props.dark])

    // useFrame((state, delta)=>{
    //     if (!initiate)
    //     {
    //         if (g>0)
    //             setG(Math.max(g-0.5),0);
    //     }
        
    // });

    return <>
    <EffectComposer enabled={(props.scrollData.range(0,1)>0.3 && props.scrollData.range(0,1)<0.6)  || props.dark} stencilBuffer>
        {props.scrollData.range(0,1)>0.3? <Bloom stencilBuffer mipmapBlur intensity={0.65}/> :null}
        {/* <Pixelation
            granularity={g} // pixel granularity
        /> */}
        {props.dark? <HueSaturation
            blendFunction={BlendFunction.NORMAL} // blend mode
            hue={0} // hue in radians
            saturation={-1} // saturation in radians
        />:null}
    </EffectComposer>
    </>
}